import { orderBy } from 'lodash'

export default {
  activeindex (state) {
    return state.activeArticleIndex
  },
  user (state) {
    return state.userData
  },
  isAuthenticated (state) {
    return state.accessToken !== null
  }

}
