<template>
  <div class="contactus-widget-container">
    <div v-if="!authUser" @click="showLoginPopUp" class="clickable login-btn"> {{sendbtntext}}
    </div>
   
    <div class id="signup-pop-up" v-if="showLogin">
      <div class="signup-form">
        <div class="cross clickable" :style="{ color:fontcolor }" @click="closeLoginPopUp"></div>
        <div id="form" class ref="login " v-if="showFormlogin">
          <h1 class="title" :style="{ color:fontcolor }" >{{heading}}</h1>
          <h2 class="title" :style="{ color:fontcolor }">{{title}}</h2>
          <div class="widget-input-cont">
            <input required="true" class="widget-inputText" :style="{ color:fontcolor }" type="text" v-model="emailAddress" />
            <span class="widget-floating-label" :style="{ color:fontcolor }">Email-id</span>
          </div>
          <div class="widget-input-cont">
            <input required="true" class="widget-inputText" :style="{ color:fontcolor }" type="password" v-model="password" />
            <span class="widget-floating-label" :style="{ color:fontcolor }">Password</span>
          </div>
          <div class="widget-input-cont">
            <div :style="{ color:fontcolor }"
              class=" forgotpassword"
              @click="testpassword"
            >Forgot Password ?</div>
          </div>
          <div class="widget-input-cont widthmax">
            <button :style="{ color: buttontextcolor, backgroundColor:buttonbgcolor,borderColor:buttonbgcolor }"
              class="widget-btn-primary"
              @click="handleLogin"
              :disabled="btndisable"
            >{{sendbtntext}}</button>
          </div>
          <div class="widget-success-msg" v-if="showerr">{{showresmsg}}</div>
        </div>
        <div id="form" class v-if="showresetpassword">
          <h1 class="title" :style="{ color:fontcolor }">Forgot Password</h1>
          <h2 class="title" :style="{ color:fontcolor }">{{title}}</h2>
          <div class="widget-input-cont">
            <input required="true" class="widget-inputText" :style="{ color:fontcolor }" type="text" v-bind="emailAddress1" />
            <span class="widget-floating-label" :style="{ color:fontcolor }" >Email-id</span>
          </div>
          <div class="widget-input-cont widthmax">
            <button class="widget-btn-primary" :style="{ color: buttontextcolor, backgroundColor:buttonbgcolor,borderColor:buttonbgcolor }" @click="sendDataForgot" :disabled="btndisable">Send Email</button>
          </div>
          <div class="widget-success-msg" v-if="showerr">{{showresmsg}}</div>
        </div>
      </div>
      <div class="signup-image"></div>
    </div>
        
      
  
   
      <div v-if="authUser"  class="logout-btn clickable desktop-header-item font-bold">
        <button class="header-button" @click="logout"> Hello {{user}} |  logout</button>
       </div>
   
  </div>

  <!-- ref used for show hide on click of btn-->
</template>
<style scoped>
.userinfo{
  float:left;
}
.login-btn {
  line-height: 32px;
  height: 32px;
  width: 100px;
  font-size: 18px;
  text-align: center;
  font-weight: 800;
}

.logout-btn {
  padding-right: 10px; 
  padding-left : 20px;float:left;
}

#signup-pop-up {
  position: fixed;
  cursor: auto;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  display: flex;
}
.widget-success-msg{
  color: red;
}
.signup-form {
  position: relative;
  width: 50%;
  min-width: 300px;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 500px;
  max-width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.signup-image {
  display: block;
  background-image: url(https://www.pufikhomes.com/wp-content/uploads/2018/02/creative-colorful-home-in-denmark-pufikhomes-2.jpg);
  height: 100vh;
  width: 50%;
  right: 0;
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 70%;
}
. .space-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

#form {
  padding: 5px;
  margin-top: 30px;
}
.title {
  text-align: center;
  color: #611f69;
}
.widget-input-cont {
  position: relative;
  margin-top: 44px;
  width: 100%;
  display: inline-flex;
}

.widget-btn-primary {
  width: 50%;
  background-color: #611f69;
  padding-right: 30px;
  -webkit-transition: all 0.3s ease 0.2s;
  -webkit-transition: all 0.3s ease 0.1s;
  transition: all 0.3s ease 0.1s;
  text-shadow: none;
  border-width: 0;
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  color: #FFF;
  position: relative;
  display: inline-block;
  padding: 10px 4px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 0 auto;
}
.widget-btn-primary:hover {
  background-color: #611f69;
  opacity: 0.9;
}
.widget-inputText {
  width: 100%;
  color: #611f69;
  padding: 5px;
  padding-left: 5px;
  font-size: 16px;
  outline: none !important;
  border: none;
  border-bottom: 1px solid;
}

.widget-floating-label {
  text-align: left;
  position: absolute;
  width: 100%;
  pointer-events: none;
  left: 5px;
  top: 50%;
  color: #611f69;
  transform: translate(0px, -50%);
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
.widget-inputText:focus ~ .widget-floating-label,
.widget-inputText:not(:focus):valid ~ .widget-floating-label {
  top: -20%;
  color: #611f69;
  margin: 0 auto;
  left: 0px;
  font-size: 14px;
  opacity: 1;
  padding-left: 5px;
}
.textarea-widget:focus ~ .widget-floating-label,
.textarea-widget:not(:focus):valid ~ .widget-floating-label {
  top: 20%;
  color: #611f69;
  margin: 0 auto;
  font-size: 14px;
  opacity: 1;
}

.forgotpassword {
  font-size: 1.2em;
  cursor: pointer;
  color: #611f69;
}
.forgotpassword:hover {
  text-decoration: underline;
}
.cross {
  position: fixed;
  top: 20px;
  left: 20px;
  margin: 0;
  font-size: 2em;
  text-shadow: 1px 1px 5px #333;
  color:#611f69;
}
.cross:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
}
@media only screen and (max-width: 600px) {
  .contactus-widget-container {
    margin: 0 auto;
    min-width: 96%;
    overflow: hidden;
    max-width: 100%;
  }
  .signup-image {
    display: none;
  }
  .widget-input-cont {
    width: 100%;
  }
  .widget-inputText {
    width: 96%;
  }
}
</style>

<script>
export default {
  name: "loginForm",
  components: {},

  beforeCreate() {},
  created() {
    var self = this;
    console.log(this.$root.redetail);
    var Data = {};
    this.customerid = this.$root.customerid;
    this.title = this.$root.titletext;
    this.heading = this.$root.heading;
    this.showTitle = this.$root.showtitle;
    this.showEmailAddress = this.$root.showemailaddress; 
    this.sendbtntext = this.$root.sendbtntext;
    this.buttonbgcolor= this.$root.buttonbgcolor;
    this.buttontextcolor= this.$root.buttontextcolor;
    this.fontcolor= this.$root.fontcolor;
    this.logohref= this.$root.logohref;

  },
  mounted() {},
  data: () => ({
    showFormlogin :true,
    showresetpassword: "",
    showBtn: true,
    showPopUp: false,
    showCaptch: false,
    captchaSitekey: null,
    recaptchaMessage: "Please check Recaptcha!",
    submitted: false,
    sendbtntext: "",
    btndisable: false,
    title: "",
    heading: "",
    showresmsg: "",
    emailAddress: "",
    password: "",
    showerr: "",
    user: ""
  }),
  computed: {
    showLogin() {
      return !this.showBtn && !this.$store.getters.isAuthenticated;
    },

    authUser() {
      this.user = this.$store.getters.user.name;
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    getHrefEmail(email) {
      return "mailto:" + email;
    },
    onCaptchaVerified() {
      this.recaptchaVerified = true;
    },
    onCaptchaExpired() {
      this.recaptchaVerified = false;
    },
    ValidateEmail(mail) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (mail.match(mailformat)) {
        return true;
      } else {
        alert("You have entered an invalid email address!");
        return false;
      }
    },
    testpassword(){
       this.showFormlogin = false;
      this.showresetpassword = true;
      this.showerr = false;
    },
    handleForgotClick() {
      this.showLogin = false;
      this.showresetpassword = true;
      // hide login div and show forgot password div
    },
    showLoginPopUp() {
      this.showBtn = false;
      this.showPopUp = true;
    },
    handleLogin() {
      this.showerr = false;
      var shajs = require("sha.js");
      var password = shajs("sha256")
        .update(this.password)
        .digest("hex");

      const formData = {
        email: this.emailAddress,
        password: password
      };

      if (this.customerid) {
        formData.customerid = this.customerid;
      }

      console.log(formData);
      this.$store.dispatch("signIn", formData).then(res =>{

      }).catch((err)=>{
        if(err.response.data.error){
        this.showerr = true;
        this.showresmsg = err.response.data.error.message;
        this.emailAddress = "";
        this.password = "";
        }
        
      })
    },
    sendDataForgot(){
      this.showerr = true;
      this.showresmsg = "Invalid email valid";
    },
    logout() {
       this.showBtn = true;
      this.showPopUp = false;
      this.$store.dispatch("logout");
    },

    closeLoginPopUp(){
      this.showBtn = true;
      this.showPopUp = false;
       this.showFormlogin = true;
      this.showresetpassword = false;
      this.showresmsg ="";
      this.showerr = false;

    }
    
  }
};
</script>
