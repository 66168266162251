import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userData: {},
		accessToken: null
	},
	getters,
	mutations,
	actions: {
		signIn({ commit, dispatch }, payload) {
			var dyHeader = {};
			if (true) {
				dyHeader.customerId = payload.customerid
			} else {
				dyHeader.customerId = payload.customerid
			}
			var self = this;
			self.customerId = payload.customerid
			var data = payload;
			delete data.customerid;
			return new Promise((resolve, reject) => {
				axios.post('login', data, {
					headers: dyHeader
				}).then(function (response) {
					if (response.status == 200) {
						var authResponse = response.data
						if (authResponse.response.user_token != null) {
							commit('setToken', authResponse.response.user_token)
							localStorage.setItem('accesstoken', authResponse.response.user_token)
							dispatch('fetchUser', authResponse.response);
						}
						resolve(response);
					}
					reject(response);
				})
					.catch(function (error, response) {
						reject(error);
					})
			});
		},
		fetchUser({ commit }, userData) {
			console.log(userData)
			var dyHeader = {customerid:self.customerId};
			var data = ''
			return new Promise((resolve, reject) => {
				axios.get('account',{headers:dyHeader}).then(function (response) {
					if (response.status == 200) {
						localStorage.setItem('username', response.data.response.name);
						localStorage.setItem('userid', response.data.response.userid);
						commit('storeUser', response.data.response)
						resolve(response);
					}
					reject(response);
				})
					.catch(function (error, response) {
						reject(error);
					})


			});
		},
		logout({ commit }) {
			commit('clearAuthData')
			localStorage.removeItem('accesstoken')
			localStorage.removeItem('username')
			localStorage.removeItem('userid')
		},
		tryAutoLogin({ commit }) {
			const token = localStorage.getItem('accesstoken')
			if (!token) {
				return
			}
			const userId = localStorage.getItem('userid')
			const username = localStorage.getItem('username')
			if (!userId || !username) { return }
			commit('setToken', token)
			commit('storeUser', { name: username, userid: userId })



		}
	}
})
