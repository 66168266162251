import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import axios from 'axios'
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
Vue.config.productionTip = false
Vue.use(vueCustomElement)
Vue.config.productionTip = false
App.store = store
App.router = router
Vue.customElement('banner-video', App)


axios.defaults.baseURL = 'https://api.appmastery.co/api/v1/apps/'

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    var token = localStorage.getItem('accesstoken')
    if (token) {
        config.headers['authorization'] = token;
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

