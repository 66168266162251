export default {
    setToken(state, token) {
        state.accessToken = token;
    },
    storeUser(state, userData) {
        var data={name:userData.name,userId:userData.userid}
        state.userData = data;
    },
    clearAuthData (state) {
        state.accessToken = null
        state.userData = {}
      }
}
