<template>
  <div class="header-video">
    <div data-v-71276cfc data-v-09f4465b class="landing-page-header flex">
      <a :href="logohref" style="text-decoration: none;"><div 
        data-v-71276cfc
        class="clickable ico-logo-white"
        :style="{ backgroundImage: `url(${logoURL})` }"
      ></div>
      </a>
      <div data-v-71276cfc class="right-side">
<!--         <div data-v-71276cfc class="ico-hamburger-white clickable"></div> -->
        <!---->
        <div data-v-0fd1865a data-v-71276cfc class="menu-item clickable" :style="{ color: fontcolor}">
          <!---->
          <!---->
          <a href="/services.html" style="text-decoration: none;">
          <div data-v-0fd1865a class="menu-text">Services</div>
          </a>
          <!---->
        </div>
        <div data-v-0fd1865a data-v-71276cfc class="menu-item clickable" :style="{ color: fontcolor}">
          <!---->
          <!---->
          <a href="/about.html" style="text-decoration: none;">
          <div data-v-0fd1865a class="menu-text">About Us</div>
          </a>
          <!---->
        </div>        
        <div data-v-0fd1865a data-v-71276cfc class="menu-item clickable" :style="{ color: fontcolor}">
          <!---->
          <!---->
          <a href="/FAQ.html" style="text-decoration: none;">
          <div data-v-0fd1865a class="menu-text">FAQs</div>
          </a>
          <!---->
        </div>
        
        <div data-v-0fd1865a data-v-71276cfc class="header-button flex clickable" :style="{ color: buttontextcolor, backgroundColor:buttonbgcolor,borderColor:buttonbgcolor }">
          <!---->
          <!---->
          <div data-v-0fd1865a><login /></div>
          <!---->
        </div>
      </div>
    </div>
    <div data-v-6252ddc3 data-v-09f4465b class="landing-content-1">
      <!---->
      <div data-v-6252ddc3 class="homepage">
        <div data-v-6252ddc3 class="landing-image">
          <!---->
          <video
            loop="true"
            data-v-6252ddc3
            id="hero-video"
            autoplay="autoplay"
            muted="muted"
            :src="bgvideo"
            playsinline="1"
            data-preload="`${basePath}asset.url`"
            data-preload-type="video"
            class="landscape-screen"
          ></video>
        </div>
        <div data-v-6252ddc3 class="landing-text-wrapper">
          <div data-v-6252ddc3 class="landing-text">
            <div
              data-v-6252ddc3
              class="font-x-large font-bold main-text"
            >{{bannertitle}}</div>
            <div
              data-v-6252ddc3
              class="sub-text font-small"
              style="width: auto;"
            >{{bannertext}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.clickable {
  cursor: pointer;
}
.menu-item{
    line-height: 32px;
    height: 32px;
    margin-right: 30px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.menu-text{
  color: #fff;
  text-shadow: 1px 1px 5px #333;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header-video {
  position: relative;
  overflow: hidden;
}
.header-video div ::after, ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.landing-page-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 90vw;
  height: 100px;
  color: inherit;
  z-index: 1;
  padding: 20px 32px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ico-logo-white {
  height: 32px;
  width: 300px;
  background-size: contain;
  background-position-y: center;
  background-repeat: no-repeat;
}
.right-side {
  width: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-button {
  background-color: #611f69;
  border: 2px solid #611f69;
  height: 28px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 6px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
}
.landing-content-1 {
  position: relative;
}
.landing-content-1,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.landing-image {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
#hero-video {
  position: relative;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  width: 100vw;
  object-fit: contain;
}
.landing-text-wrapper {
  position: absolute;
  bottom: 15vw;
  left: 5vw;
  color:#fff;
}
.landing-text {
  color: inherit;
  line-height: 1.2;
}
.landing-text {
  text-shadow: 1px 1px 5px #333;
}

.font-bold {
  font-weight: 800;
}
.font-x-large {
  font-size: 42px;
  line-height: 44px;
  font-weight: 500;
}
.landing-text .sub-text {
  margin: 10px 0;
  text-shadow: 1px 1px 5px #333;
}

.font-small,
.font-x-small {
  line-height: 28px;
  font-weight: 500;
}
.font-small {
  font-size: 26px;
}
@media (max-aspect-ratio: 3/5) {
  .ico-logo-white{
    width: 100px;
    height: 75px;
    margin-top: 25px;
    margin-left: 0px !important;
  }
  .right-side{
    margin-top: 25px;
    margin-left: 25px;
  }
  .menu-item{
    line-height: 32px;
    height: 32px;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
  }
  .header-button{
    padding: 0px 4px;
  }
  .landing-page-header{
    width: 100vw;
    padding: 8px 8px;
  }
  #hero-video {
    left: 50%;
    right: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    height: 100vh;
    width: initial;
  }
}
</style>

<script>
import login from "@/components/loginForm.vue";
export default {
  name: "BannerVideo",
  components: {
    login
  },
  created() {
    this.bgvideo = this.$root.bgvideo;
    this.logoURL = this.$root.logourl;
    this.buttonbgcolor= this.$root.buttonbgcolor;
    this.buttontextcolor= this.$root.buttontextcolor;
    this.fontcolor= this.$root.fontcolor;
    this.logohref= this.$root.logohref;
    this.menuhrefone= this.$root.menuhrefone;
    this.menutextone= this.$root.menutextone;
    this.menuhreftwo= this.$root.menuhreftwo;
    this.menutexttwo= this.$root.menutexttwo;
    this.bannertitle= this.$root.bannertitle;
    this.bannertext= this.$root.bannertext;

  },
  mounted() {},
  data: () => ({
    bgvideo: "",
    logoURL: ""
  }),
  computed: {},
  methods: {
    gethref(id) {
      return this.$root.redetail + id;
    }
  }
};
</script>
