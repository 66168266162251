<template>
  <div id="app">
    <BannerVideo />
  </div>
</template>

<script>
import BannerVideo from "@/components/banner_video.vue";
export default {
  components: {
    BannerVideo
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("tryAutoLogin");
  },
  props: {
    customerid: {
      type: String,
      default: () => ""
    },
    sendbtntext: {
      type: String,
      default: () => "send"
    },
    heading: {
      type: String,
      default: () => "heading comes here"
    },
    showtitle: {
      type: String,
      default: () => "true"
    },
    bgvideo: {
      type: String,
      default: () =>
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
    },
    logourl: {
      type: String,
      default: () =>
        "https://s3.amazonaws.com/appdashweb/html5-templates/app-assets/white-am-Asset.png"
    },    
    buttonbgcolor: {
      type: String,
      default: () =>
        ""
    },
    buttontextcolor: {
      type: String,
      default: () =>
        ""
    },    
    fontcolor: {
      type: String,
      default: () =>
        ""
    },    
    logohref: {
      type: String,
      default: () =>
        "#"
    },    
    menutextone: {
      type: String,
      default: () =>
        "Services"
    },    
    menuhrefone: {
      type: String,
      default: () =>
        "/services.html"
    },
    menutexttwo: {
      type: String,
      default: () =>
        "About Us"
    },    
    menuhreftwo: {
      type: String,
      default: () =>
        "/about.html"
    },    
    bannertitle: {
      type: String,
      default: () =>
        "A better way to engage your customers"
    },   
    bannertext: {
      type: String,
      default: () =>
        "Create and publish 3D and AR experiences with no coding"
    }
  }
};
</script>
